<script setup lang="ts">
import { getIndexFromPath } from '@manager'
import ManagerGroup from './Group.vue'
import type { IManagerGroupEmits, IManagerGroupProps } from './types'

const props = withDefaults(defineProps<IManagerGroupProps>(), {
  parentValue: () => ({}),
  showMenu: false,
})
const emit = defineEmits<IManagerGroupEmits>()

// The value is managed by the ManagerGroup
const parentValue = useVModel(props, 'parentValue', emit)

const title = computed(() => {
  const label = props.node.label ?? ''
  const groupIndex = getIndexFromPath(props.parentPath)
  return groupIndex !== undefined
    ? label.replace('{index}', String(groupIndex + 1))
    : label
})
</script>

<template>
  <!-- Field Section -->
  <ManagerGroup v-bind="props" v-model:parent-value="parentValue">
    <template #header>
      <!-- Title Component   -->
      <div>
        <div class="flex justify-between">
          <h3 class="text-md font-medium text-white">{{ title }}</h3>

          <ManagerSharedFieldSectionMenu v-if="showMenu">
            <ManagerSharedFieldSectionMenuItem
              icon="trash"
              class="hover:bg-red/20 text-red-500"
              @click="emit('remove')"
            >
              Remove {{ parentNode?.label }}
            </ManagerSharedFieldSectionMenuItem>
          </ManagerSharedFieldSectionMenu>
        </div>
        <hr class="bg-gray-750 mt-2 h-px border-t-0 border-dotted" />
      </div>
    </template>
  </ManagerGroup>
</template>
